import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { PlansService } from 'src/app/components/plans/plans.service';
import { PricingService } from '../../services/pricing.service';
import { Transition } from '@uirouter/angular';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit {

  @Input() isUnlimitedPlan: boolean;
  @Input() periodMonthly: boolean;
  _displayCount: number = 5;
  get displayCount(): number {
    return this._displayCount;
  }
  @Input() set displayCount(value: number) {
    this._displayCount = Math.floor(value);
    this.displayCountChange.emit(this._displayCount);
  }
  _unlimitedCount: number = 1;
  get unlimitedCount(): number {
    return this._unlimitedCount;
  }
  @Input() set unlimitedCount(value: number) {
    this._unlimitedCount = Math.floor(value);
    this.unlimitedCountChange.emit(this._unlimitedCount);
  }
  @Input() planType?: string;
  @Input() subscriptionId?: string;
  @Output() isUnlimitedPlanChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isVolumePlan: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() periodMonthlyChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedProductCodeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() totalPriceChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() displayCountChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() unlimitedCountChange: EventEmitter<number> = new EventEmitter<number>();

  applyDiscount = this.companyStateService.isDiscountCustomer();
  canAccessUnlimitedPlan = this.companyStateService.isK12Customer();
  isBasicPlan = false;
  isAdvancedPlan = true;
  isEnterprisePlan = false;
  allowMultiple = false;

  constructor(
    private plansService: PlansService,
    private pricingService: PricingService,
    private companyStateService: CompanyStateService,
    private transition: Transition
  ) { }

  ngOnInit(): void {
    const enterprise = Number(this.transition.params().enterprise);
    const unlimited = Number(this.transition.params().unlimited);

    if (!isNaN(enterprise) || !isNaN(unlimited)) {
      this.allowMultiple = this.companyStateService.isK12Customer();
      this.isAdvancedPlan = false;
      this.isUnlimitedPlan = !isNaN(unlimited) && unlimited > 0;
      if (!isNaN(enterprise) && enterprise > 0) {
        this.displayCount = enterprise;
        this.isEnterprisePlan = true;
      }
      if (this.isUnlimitedPlan) {
        this.unlimitedCount = unlimited;
      }

      // No plan was selected, use defaults
      if (!this.isUnlimitedPlan && !this.isEnterprisePlan) {
        if (this.companyStateService.isK12Customer()) {
          this.isUnlimitedPlan = true;
        } else {
          this.isEnterprisePlan = true;
        }
      }
    } else if (this.isUnlimitedPlan) {
      this.isAdvancedPlan = false;
    } else if (this.planType === 'advanced') {
      this.isAdvancedPlan = false;
      this.isEnterprisePlan = true;
    }
    this._changePlan();
  }

  get isPerDisplayPlan(): boolean {
    return this.isBasicPlan || this.isAdvancedPlan || this.isEnterprisePlan;
  }

  get selectedProductCode(): string | undefined {
    const plan = this.isBasicPlan
      ? this.plansService.getBasicPlan(this.applyDiscount)
      : this.isAdvancedPlan
        ? this.plansService.getAdvancedPlan(this.applyDiscount)
        : this.isEnterprisePlan
          ? this.plansService.getEnterprisePlan(this.applyDiscount)
          : this.isUnlimitedPlan
          ? this.plansService.getUnlimitedPlan()
            : undefined;
    const productCode = plan ? plan.productCode : undefined;
    return productCode;
  }

  get totalPrice(): number {
    let total = 0;
    if (this.isUnlimitedPlan) {
      total += this.unlimitedTotal;
    }
    if (this.isPerDisplayPlan) {
      total += this.displayTotal;
    }
    return total;
  }

  get unlimitedPrice(): number {
    return this.plansService.getUnlimitedPlan().yearly.billAmount;
  }

  get unlimitedTotal(): number {
    return this.unlimitedPrice * this.unlimitedCount;
  }

  get displayTotal(): number {
    return this.pricingService.getTotalPrice(
      this.periodMonthly && this.isBasicPlan,
      this.displayCount,
      this.applyDiscount,
      this.selectedProductCode
    );
  }

  get unlimitedMonthlyPrice(): number {
    return this.plansService.getUnlimitedPlan().yearly.billAmount / 12;
  }

  get baseDisplayPrice(): number {
    if (this.isBasicPlan) {
      const monthlyPrice = this.pricingService.getBasePricePerDisplay(1, this.selectedProductCode);
      return this.periodMonthly ? monthlyPrice : monthlyPrice * 12;
    }
    return this.pricingService.getPricePerDisplay(false, 1, false, this.selectedProductCode);
  }

  get pricePerDisplay(): number {
    return this.pricingService.getPricePerDisplay(
      this.periodMonthly && this.isBasicPlan,
      this.displayCount,
      this.applyDiscount,
      this.selectedProductCode
    );
  }

  get basicPricePerDisplay(): number {
    return this.pricingService.getPricePerDisplay(
      this.periodMonthly,
      this.displayCount,
      this.applyDiscount,
      this.plansService.getBasicPlan(this.applyDiscount).productCode
    );
  }

  get advancedPricePerDisplay(): number {
    return this.pricingService.getPricePerDisplay(
      false,
      this.displayCount,
      this.applyDiscount,
      this.plansService.getAdvancedPlan(this.applyDiscount).productCode
    );
  }

  get enterprisePricePerDisplay(): number {
    return this.pricingService.getPricePerDisplay(
      false,
      this.displayCount,
      this.applyDiscount,
      this.plansService.getEnterprisePlan(this.applyDiscount).productCode
    );
  }

  get yearlySavings(): number {
    if (this.isUnlimitedPlan && !this.isPerDisplayPlan) {
      return 0;
    }

    const isMonthlyBilling = this.periodMonthly && this.isBasicPlan;
    const baseTotal = this.baseDisplayPrice * this.displayCount;
    const annualBaseTotal = isMonthlyBilling ? baseTotal * 12 : baseTotal;
    const annualActualTotal = isMonthlyBilling ? this.displayTotal * 12 : this.displayTotal;

    return annualBaseTotal - annualActualTotal;
  }

  private _changePlan() {
    this.selectedProductCodeChange.emit(this.selectedProductCode);
    this.isUnlimitedPlanChange.emit(this.isUnlimitedPlan);
    this.isVolumePlan.emit(this.isBasicPlan || this.isAdvancedPlan || this.isEnterprisePlan);
    this.totalPriceChange.emit(this.totalPrice);
    this.periodMonthlyChange.emit(this.isBasicPlan ? this.periodMonthly : false);
  }

  updatePeriodMonthly(value: boolean) {
    this.periodMonthly = value;
    this.periodMonthlyChange.emit(value);
  }

  selectBasic() {
    if (!this.allowMultiple) {
      if (this.planType !== 'basic' && (!this.isBasicPlan || this.isUnlimitedPlan)) {
        this.isBasicPlan = true;
        this.isAdvancedPlan = false;
        this.isEnterprisePlan = false;
        this.isUnlimitedPlan = false;
      }
      this._changePlan();
    }
  }

  selectAdvanced() {
    if (!this.allowMultiple) {
      if (this.planType !== 'advanced' && (!this.isAdvancedPlan || this.isUnlimitedPlan)) {
        this.isBasicPlan = false;
        this.isAdvancedPlan = true;
        this.isEnterprisePlan = false;
        this.isUnlimitedPlan = false;
      }
      this._changePlan();
    }
  }

  selectEnterprise() {
    if (this.planType !== 'enterprise' && !this.isEnterprisePlan) {
      this.isBasicPlan = false;
      this.isAdvancedPlan = false;
      this.isEnterprisePlan = true;
      if (!this.allowMultiple) {
        this.isUnlimitedPlan = false;
      }
    } else if (this.isEnterprisePlan) {
      this.isEnterprisePlan = false;
      if (this.allowMultiple) {
        this.isUnlimitedPlan = true;
      }
    }
    this._changePlan();
  }

  selectUnlimited() {
    if (this.planType !== 'unlimited' && !this.isUnlimitedPlan) {
      this.isBasicPlan = false;
      this.isAdvancedPlan = false;
      if (!this.allowMultiple) {
        this.isEnterprisePlan = false;
      }
      this.isUnlimitedPlan = true;
    } else if (this.isUnlimitedPlan) {
      this.isUnlimitedPlan = false;
      if (this.allowMultiple) {
        this.isEnterprisePlan = true;
      }
    }
    this._changePlan();
  }

  selectEnterpriseOrUnlimited() {
    if (this.isBasicPlan || this.isAdvancedPlan) {
      this.canAccessUnlimitedPlan ? this.selectUnlimited() : this.selectEnterprise();
    }
  }

  updateDisplayCount(value) {
    this.displayCount = Math.max(this.displayCount + value, 1);
  }

  updateUnlimitedCount(value) {
    this.unlimitedCount = Math.max(this.unlimitedCount + value, 1);
  }

}
