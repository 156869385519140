import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  static readonly LEGACY_PRODUCT_CODE = '6dc959e7c483bfd5af3dbbc7ccba2282de6b7336';

  public static readonly PRICING_DATA = [{
    'id': '6dc959e7c483bfd5af3dbbc7ccba2282de6b7336-usd01y',
    'name': 'Digital Displays Annual Plan 2022',
    'invoice_name': 'Digital Displays Annual Plan',
    'period': 1,
    'period_unit': 'year',
    'pricing_model': 'volume',
    'free_quantity': 0,
    'status': 'active',
    'enabled_in_hosted_pages': true,
    'enabled_in_portal': true,
    'addon_applicability': 'all',
    'tax_code': 'SW052000',
    'accounting_code': '4112',
    'is_shippable': false,
    'updated_at': 1561683395,
    'giftable': false,
    'resource_version': 1561683395680,
    'object': 'plan',
    'charge_model': 'volume',
    'taxable': true,
    'currency_code': 'USD',
    'tiers': [{
      'starting_unit': 1,
      'ending_unit': 2,
      'price': 12650,
      'object': 'tier'
    }, {
      'starting_unit': 3,
      'ending_unit': 10,
      'price': 11550,
      'object': 'tier'
    }, {
      'starting_unit': 11,
      'ending_unit': 69,
      'price': 10450,
      'object': 'tier'
    }, {
      'starting_unit': 70,
      'price': 9350,
      'object': 'tier'
    }],
    'show_description_in_invoices': false,
    'show_description_in_quotes': false
  }, {
    'id': '6dc959e7c483bfd5af3dbbc7ccba2282de6b7336-usd01m',
    'name': 'Digital Displays Monthly Plan 2022',
    'invoice_name': 'Digital Displays Monthly Plan',
    'period': 1,
    'period_unit': 'month',
    'trial_period': 14,
    'trial_period_unit': 'day',
    'pricing_model': 'volume',
    'free_quantity': 0,
    'status': 'active',
    'enabled_in_hosted_pages': true,
    'enabled_in_portal': true,
    'addon_applicability': 'all',
    'tax_code': 'SW052000',
    'accounting_code': '4112',
    'is_shippable': false,
    'updated_at': 1575992258,
    'giftable': false,
    'resource_version': 1575992258496,
    'object': 'plan',
    'charge_model': 'volume',
    'taxable': true,
    'currency_code': 'USD',
    'tiers': [{
      'starting_unit': 1,
      'ending_unit': 2,
      'price': 1150,
      'object': 'tier'
    }, {
      'starting_unit': 3,
      'ending_unit': 10,
      'price': 1050,
      'object': 'tier'
    }, {
      'starting_unit': 11,
      'ending_unit': 69,
      'price': 950,
      'object': 'tier'
    }, {
      'starting_unit': 70,
      'price': 850,
      'object': 'tier'
    }],
    'show_description_in_invoices': false,
    'show_description_in_quotes': false,
    'meta_data': {
      'quantity_meta': {
        'type': 'range',
        'min': '1',
        'max': '50',
        'step': '1'
      }
    }
  },
  // 2022 plans
  {
    "id": "e239fe83aa3203eab670e54434eb4285204d6f51-usd01y",
    "name": "Basic Displays Annual Plan 2022",
    "invoice_name": "Basic Displays Annual Plan",
    "period": 1,
    "period_unit": "year",
    "pricing_model": "volume",
    "free_quantity": 0,
    "status": "active",
    "enabled_in_hosted_pages": true,
    "enabled_in_portal": true,
    "addon_applicability": "all",
    "tax_code": "SW052000",
    "sku": "basic-display-license-1yr-usd",
    "accounting_code": "4112",
    "is_shippable": false,
    "updated_at": 1660924906,
    "giftable": false,
    "resource_version": 1660924906899,
    "object": "plan",
    "charge_model": "volume",
    "taxable": true,
    "currency_code": "USD",
    "tiers": [
      {
        "starting_unit": 1,
        "ending_unit": 2,
        "price": 13200,
        "object": "tier"
      },
      {
        "starting_unit": 3,
        "ending_unit": 10,
        "price": 12000,
        "object": "tier"
      },
      {
        "starting_unit": 11,
        "ending_unit": 69,
        "price": 10800,
        "object": "tier"
      },
      {
        "starting_unit": 70,
        "price": 9600,
        "object": "tier"
      }
    ],
    "cf_plan_groups": "Display",
    "show_description_in_invoices": false,
    "show_description_in_quotes": false,
    "channel": "web"
  },
  {
    "id": "e239fe83aa3203eab670e54434eb4285204d6f51-usd01m",
    "name": "Basic Displays Monthly Plan 2022",
    "invoice_name": "Basic Displays Monthly Plan",
    "period": 1,
    "period_unit": "month",
    "trial_period": 14,
    "trial_period_unit": "day",
    "pricing_model": "volume",
    "free_quantity": 0,
    "status": "active",
    "enabled_in_hosted_pages": true,
    "enabled_in_portal": true,
    "addon_applicability": "all",
    "tax_code": "SW052000",
    "sku": "basic-display-license-mnth-usd",
    "accounting_code": "4112",
    "is_shippable": false,
    "updated_at": 1660924784,
    "giftable": false,
    "resource_version": 1660924784736,
    "object": "plan",
    "charge_model": "volume",
    "taxable": true,
    "currency_code": "USD",
    "tiers": [
      {
        "starting_unit": 1,
        "ending_unit": 2,
        "price": 1200,
        "object": "tier"
      },
      {
        "starting_unit": 3,
        "ending_unit": 10,
        "price": 1100,
        "object": "tier"
      },
      {
        "starting_unit": 11,
        "ending_unit": 69,
        "price": 1000,
        "object": "tier"
      },
      {
        "starting_unit": 70,
        "price": 900,
        "object": "tier"
      }
    ],
    "cf_plan_groups": "Display",
    "show_description_in_invoices": false,
    "show_description_in_quotes": false,
    "channel": "web",
    "meta_data": {
      "quantity_meta": {
        "type": "range",
        "min": "1",
        "max": "50",
        "step": "1"
      }
    }
  },
  {
    "id": "be0882ea2bb856937e223809c08fc65fc98bdbde-usd01y",
    "name": "Advanced Displays Annual Plan 2022",
    "invoice_name": "Advanced Displays Annual Plan",
    "period": 1,
    "period_unit": "year",
    "pricing_model": "volume",
    "free_quantity": 0,
    "status": "active",
    "enabled_in_hosted_pages": true,
    "enabled_in_portal": true,
    "addon_applicability": "all",
    "tax_code": "SW052000",
    "sku": "advanced-display-license-1yr-usd",
    "accounting_code": "4112",
    "is_shippable": false,
    "updated_at": 1660924691,
    "giftable": false,
    "resource_version": 1660924691733,
    "object": "plan",
    "charge_model": "volume",
    "taxable": true,
    "currency_code": "USD",
    "tiers": [
      {
        "starting_unit": 1,
        "ending_unit": 2,
        "price": 15600,
        "object": "tier"
      },
      {
        "starting_unit": 3,
        "ending_unit": 10,
        "price": 14400,
        "object": "tier"
      },
      {
        "starting_unit": 11,
        "ending_unit": 69,
        "price": 13200,
        "object": "tier"
      },
      {
        "starting_unit": 70,
        "price": 12000,
        "object": "tier"
      }
    ],
    "cf_plan_groups": "Display",
    "show_description_in_invoices": false,
    "show_description_in_quotes": false,
    "channel": "web"
  },
  {
    "id": "72f8d32e9cc37fa10fd174515221b53e042a7f70-usd01y",
    "name": "Enterprise Displays Annual Plan 2022",
    "invoice_name": "Enterprise Displays Annual Plan",
    "period": 1,
    "period_unit": "year",
    "pricing_model": "volume",
    "free_quantity": 0,
    "status": "active",
    "enabled_in_hosted_pages": true,
    "enabled_in_portal": true,
    "addon_applicability": "all",
    "tax_code": "SW052000",
    "sku": "enterprise-display-license-1yr-usd",
    "accounting_code": "4112",
    "is_shippable": false,
    "updated_at": 1660925020,
    "giftable": false,
    "resource_version": 1660925020470,
    "object": "plan",
    "charge_model": "volume",
    "taxable": true,
    "currency_code": "USD",
    "tiers": [
      {
        "starting_unit": 1,
        "ending_unit": 2,
        "price": 18000,
        "object": "tier"
      },
      {
        "starting_unit": 3,
        "ending_unit": 10,
        "price": 16800,
        "object": "tier"
      },
      {
        "starting_unit": 11,
        "ending_unit": 69,
        "price": 15600,
        "object": "tier"
      },
      {
        "starting_unit": 70,
        "price": 14400,
        "object": "tier"
      }
    ],
    "cf_plan_groups": "Display",
    "show_description_in_invoices": false,
    "show_description_in_quotes": false,
    "channel": "web"
  },
  {
    "id": "5e261e0fc26d4d019271aa42998ac99349fb02a3-usd01y",
    "name": "Basic Displays Annual Plan Education 2022",
    "invoice_name": "Education/Non-Profit Basic Displays Annual Plan",
    "period": 1,
    "period_unit": "year",
    "pricing_model": "volume",
    "free_quantity": 0,
    "status": "active",
    "enabled_in_hosted_pages": true,
    "enabled_in_portal": true,
    "addon_applicability": "all",
    "tax_code": "SW052000",
    "sku": "basic-display-license-edu-1yr-usd",
    "accounting_code": "4112",
    "is_shippable": false,
    "updated_at": 1661266853,
    "giftable": false,
    "resource_version": 1661266853437,
    "object": "plan",
    "charge_model": "volume",
    "taxable": true,
    "currency_code": "USD",
    "tiers": [
      {
        "starting_unit": 1,
        "ending_unit": 2,
        "price": 11400,
        "object": "tier"
      },
      {
        "starting_unit": 3,
        "ending_unit": 10,
        "price": 10800,
        "object": "tier"
      },
      {
        "starting_unit": 11,
        "ending_unit": 69,
        "price": 9600,
        "object": "tier"
      },
      {
        "starting_unit": 70,
        "price": 9000,
        "object": "tier"
      }
    ],
    "cf_plan_groups": "Display",
    "show_description_in_invoices": false,
    "show_description_in_quotes": false,
    "channel": "web"
  },
  {
    "id": "5e261e0fc26d4d019271aa42998ac99349fb02a3-usd01m",
    "name": "Basic Displays Monthly Plan Education 2022",
    "invoice_name": "Education/Non-Profit Basic Displays Monthly Plan",
    "period": 1,
    "period_unit": "month",
    "trial_period": 14,
    "trial_period_unit": "day",
    "pricing_model": "volume",
    "free_quantity": 0,
    "status": "active",
    "enabled_in_hosted_pages": true,
    "enabled_in_portal": true,
    "addon_applicability": "all",
    "tax_code": "SW052000",
    "sku": "basic-display-license-edu-mnth-usd",
    "accounting_code": "4112",
    "is_shippable": false,
    "updated_at": 1661266868,
    "giftable": false,
    "resource_version": 1661266868584,
    "object": "plan",
    "charge_model": "volume",
    "taxable": true,
    "currency_code": "USD",
    "tiers": [
      {
        "starting_unit": 1,
        "ending_unit": 2,
        "price": 1050,
        "object": "tier"
      },
      {
        "starting_unit": 3,
        "ending_unit": 10,
        "price": 950,
        "object": "tier"
      },
      {
        "starting_unit": 11,
        "ending_unit": 69,
        "price": 900,
        "object": "tier"
      },
      {
        "starting_unit": 70,
        "price": 800,
        "object": "tier"
      }
    ],
    "cf_plan_groups": "Display",
    "show_description_in_invoices": false,
    "show_description_in_quotes": false,
    "channel": "web",
    "meta_data": {
      "quantity_meta": {
        "type": "range",
        "min": "1",
        "max": "50",
        "step": "1"
      }
    }
  },
  {
    "id": "1099cb4300f1e8072c694b23afd5771882b93f08-usd01y",
    "name": "Advanced Displays Annual Plan Education 2022",
    "invoice_name": "Education/Non-Profit Advanced Displays Annual Plan",
    "period": 1,
    "period_unit": "year",
    "pricing_model": "volume",
    "free_quantity": 0,
    "status": "active",
    "enabled_in_hosted_pages": true,
    "enabled_in_portal": true,
    "addon_applicability": "all",
    "tax_code": "SW052000",
    "sku": "advanced-display-license-edu-1yr-usd",
    "accounting_code": "4112",
    "is_shippable": false,
    "updated_at": 1661266907,
    "giftable": false,
    "resource_version": 1661266907615,
    "object": "plan",
    "charge_model": "volume",
    "taxable": true,
    "currency_code": "USD",
    "tiers": [
      {
        "starting_unit": 1,
        "ending_unit": 2,
        "price": 13800,
        "object": "tier"
      },
      {
        "starting_unit": 3,
        "ending_unit": 10,
        "price": 12600,
        "object": "tier"
      },
      {
        "starting_unit": 11,
        "ending_unit": 69,
        "price": 12000,
        "object": "tier"
      },
      {
        "starting_unit": 70,
        "price": 10800,
        "object": "tier"
      }
    ],
    "cf_plan_groups": "Display",
    "show_description_in_invoices": false,
    "show_description_in_quotes": false,
    "channel": "web"
  },
  {
    "id": "aa446723ff72b6b8c1b423e2710d64bf3e97d9f6-usd01y",
    "name": "Enterprise Displays Annual Plan Education 2022",
    "invoice_name": "Education/Non-Profit Enterprise Displays Annual Plan",
    "period": 1,
    "period_unit": "year",
    "pricing_model": "volume",
    "free_quantity": 0,
    "status": "active",
    "enabled_in_hosted_pages": true,
    "enabled_in_portal": true,
    "addon_applicability": "all",
    "tax_code": "SW052000",
    "sku": "enterprise-display-license-edu-1yr-usd",
    "accounting_code": "4112",
    "is_shippable": false,
    "updated_at": 1661266746,
    "giftable": false,
    "resource_version": 1661266746775,
    "object": "plan",
    "charge_model": "volume",
    "taxable": true,
    "currency_code": "USD",
    "tiers": [
      {
        "starting_unit": 1,
        "ending_unit": 2,
        "price": 16200,
        "object": "tier"
      },
      {
        "starting_unit": 3,
        "ending_unit": 10,
        "price": 15000,
        "object": "tier"
      },
      {
        "starting_unit": 11,
        "ending_unit": 69,
        "price": 14400,
        "object": "tier"
      },
      {
        "starting_unit": 70,
        "price": 13200,
        "object": "tier"
      }
    ],
    "cf_plan_groups": "Display",
    "show_description_in_invoices": false,
    "show_description_in_quotes": false,
    "channel": "web"
  }];

  constructor() { }

  _getTierPrice(isMonthly: boolean, displayCount: number, productCode?: string): number {
    if (!productCode) {
      productCode = PricingService.LEGACY_PRODUCT_CODE;
    }
    const period = isMonthly ? 'month' : 'year';
    const planId = productCode + '-usd01' + (isMonthly ? 'm' : 'y');

    const matchedPlan = PricingService.PRICING_DATA.find((plan) => {
      return plan.period === 1 && plan.period_unit === period && plan.currency_code === 'USD' && plan.id === planId;
    });

    if (!matchedPlan) {
      console.warn('Could not find plan ' + planId);
      return 0;
    }

    const matchedTier = matchedPlan.tiers.find((tier) => {
      const upperPrice = tier.ending_unit ? tier.ending_unit : Number.MAX_SAFE_INTEGER;
      return tier.starting_unit <= displayCount && upperPrice >= displayCount;
    });

    if (!matchedTier) {
      console.warn('Could not find pricing tier for ' + displayCount + ' displays');
      return 0;
    }

    return matchedTier.price / 100;
  }

  getBasePricePerDisplay(displayCount: number, productCode?: string): number {
    return this._getTierPrice(true, displayCount, productCode);
  }

  getPricePerDisplay(isMonthly: boolean, displayCount: number, isEducation?: boolean, productCode?: string): number {
    const tierPrice = this._getTierPrice(isMonthly, displayCount, productCode);
    let pricePerDisplay = isMonthly ? tierPrice : tierPrice;

    if (isEducation && !productCode) {
      pricePerDisplay = pricePerDisplay * 0.9;
    }

    return pricePerDisplay;
  }

  getTotalPrice(isMonthly: boolean, displayCount: number, isEducation?: boolean, productCode?: string): number {
    let pricePerDisplay = this._getTierPrice(isMonthly, displayCount, productCode);
    if (isEducation && !productCode) {
      pricePerDisplay = pricePerDisplay * 0.9;
    }

    return pricePerDisplay * displayCount;
  }

}