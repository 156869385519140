import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import * as _ from 'lodash';

import { ModalService } from 'src/app/components/modals/modal.service';

import { StateService, TransitionService } from '@uirouter/angular';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { BulkDeleteConfirmationComponent } from '../bulk-delete-confirmation/bulk-delete-confirmation.component';

@Component({
  selector: 'batch-operations',
  templateUrl: './batch-operations.component.html',
  styleUrls: ['./batch-operations.component.scss']
})
export class BatchOperationsComponent implements OnInit, OnDestroy {
  @Input() listObject!: any;

  transitionHook: any;

  constructor(private stateService: StateService,
    private transitionService: TransitionService,
    private modalService: ModalService,
    private userStateService: UserStateService) {
  }

  ngOnInit(): void {
    if (this.listObject && this.listObject.batchOperations &&
      this.listObject.batchOperations.operations) {
      this._filterByRole();
      this._updateDeleteAction();
    }

    let _bypass = false;
    this.transitionHook = this.transitionService.onStart({}, (trans: any) => {
      if (_bypass) {
        _bypass = false;
        return;
      }
      if (this.listObject.batchOperations.activeOperation) {
        var operationName = this.listObject.batchOperations.activeOperation.name.toLowerCase();

        trans.abort();

        this.modalService.confirm('Cancel bulk ' + operationName + '?',
          'A bulk ' + operationName +
          ' is in progress. Navigating away from this page will cancel this action. Are you sure you want to cancel?',
          'Yes, Cancel',
          'No')
          .then(() => {
            this.listObject.batchOperations.cancel();

            _bypass = true;
            this.stateService.go(trans.to().name, trans.to().params);
          });
      }
    });

    window.onbeforeunload = (e: Event) => {
      if (this.listObject.batchOperations.activeOperation) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = true;
      }
    };
  }

  ngOnDestroy(): void {
    window.onbeforeunload = undefined;

    this.transitionHook();
  }

  _filterByRole() {
    _.remove(this.listObject.batchOperations.operations, (operation: any) => {
      return !this.userStateService.hasPermission(operation.requireRole);
    });
  }

  _updateDeleteAction() {
    _.each(this.listObject.batchOperations.operations, (operation) => {
      if (operation.isDelete) {
        operation.beforeBatchAction = () => {
          return this.modalService.showDialog(BulkDeleteConfirmationComponent, {
            selectedItems: this.listObject.getSelected(),
            itemName: this.listObject.batchOperations.name
          }, { width: this.modalService.MODAL_SM });
        };
      }
    });
  }

}